.contact-page-wrapper {
    padding: 16px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

.contact-input-wrapper {
    width: 100%;
}

.contact-input-wrapper p {
    font-size: var(--font-size-sm);
}

.contact-select {
    width: 100%;
}
