.search-page-wrapper {
    padding: 16px;
    overflow-y: auto;
    min-height: 100vh;
}
.search-page-text {
    padding: 16px 16px 16px 32px;
    color: var(--text-black-faded);
    font-size: var(--font-size-sm);
}
.search-page-text strong {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
    color: var(--text-black);
    margin-left: 4px;
}
@media screen and (max-width: 1200px) {
    .search-page-text {
        padding-left: 24px;
    }
}
@media screen and (max-width: 768px) {
    .search-page-text {
        padding: 8px 8px 8px 16px;
    }
}

ul.search-page-numbers {
    padding: 0;
    margin: 0;    
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    list-style: none;
}

.search-page-number a {
    background-color: var(--bg);
    color: var(--text-black);
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 32px;
    height: 32px;
    border: 1px solid transparent; 
    border-radius: 50%;  
    font-weight: var(--font-weight-medium); 
    transition: 0.2s;
}

.search-page-number a:hover {  
    background-color: var(--bg-black-faded); 
    box-shadow: var(--box-shadow-light); 
}

.spn-active a,
.spn-active a:hover {
    /* after hover */
    background-color: var(--main-red);
    color: var(--text-white); 
    box-shadow: var(--box-shadow-light);      
}

.search-page-number a[disabled] {
    background-color: transparent;
    color: var(--text-black-semi-faded);
    box-shadow: none;
    cursor: default;
}

.search-filters {
    width: 100%;
    height: fit-content;
    padding: 16px;
    border: 1px solid var(--divider-black);
    border-radius: 8px;
    background-color: var(--bg);
    color: var(--text-black);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}

.filter-sections {
    align-items: stretch;
    width: 100%;
    padding: 16px;    
}

.search-no-items {
    padding: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 64px;
    align-items: center;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xl);
    color: var(--text-black-faded);
    grid-column: 1 / -1;
    text-align: center;
}

.search-no-items p span {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-normal);
}
