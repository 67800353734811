.footer-wrapper {
    /*height: fit-content;*/
    color: var(--text-white);
    background-color: var(--main-red);
    height: fit-content;
    padding: 16px;
}

.footer-logo-wrapper {
    /*width: 300px;*/
}

.f-margin {
    margin-top: 32px;
}

.f-middle {
    justify-content: space-around;
    gap: 16px;
}

.f-text-small {
    font-size: var(--font-size-xs);
}
.f-logo-wrapper {
    width: 100%;
    max-width: 300px;
}

.f-list {
    list-style-type: none;
    padding-left: 0;
}

.f-inline-list li {
    display:inline;    
}

.f-inline-list li::after {
    margin-left:8px;
    margin-right:8px;
    content: "|";
}
.f-inline-list li:last-child::after {
    content: "";
}

@media screen and (min-width: 1200px) {
    .f-title {
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-medium);
    }
    .f-middle {
        justify-content: center;
        gap: 64px !important;
    }
}
