.layout-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: hidden;
}

.layout-content-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
}

.admin-path-wrapper {
}

.admin-layout-wrapper {
    display: flex;
    flex-direction: row;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    height: 100vh;
}
