/* MAJORS */

* {
    font-family: var(--font-family-sans-serif);
    font-display: swap;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--font-family-serif);
    font-display: swap;
    font-weight: var(--font-weight-bold);
}

h1 {
    font-size: 2rem;
}

address {
    font-style: normal;
}

img.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

img.fill {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

img.contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

input[type="checkbox"] {
    width: 16px;
    height: 16px;
}

/*
p { white-space: pre-line; }
*/

.img-responsive {
    object-fit: unset;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

/* FLEX CLASSES */

.flex-100 {
    flex: 1;
}

.flex-50 {
    flex: 0.5;
}

.flex-row {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 8px;
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    gap: 8px;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
}

.flex-row-center-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.flex-row-start-vertical {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
}

.flex-row-end-vertical {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
}

.flex-row-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
}

.flex-row-spacebetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}

.flex-row-spacearound {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 8px;
}

.flex-spacebetween {
    justify-content: space-between;
}

.flex-spacearound {
    justify-content: space-around;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.flex-column-spacebetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.flex-center-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-items-center {
    align-items: center;
}

.flex-stretch {
    align-content: stretch;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-spacebetween {
    justify-content: space-between !important;
}

.flex-spacearound {
    justify-content: space-around !important;
}

.flex-right-align {
    justify-content: right !important;
}

.flex-gap-none {
    gap: 0 !important;
}

.flex-gap-2 {
    gap: 2px !important;
}

.flex-gap-4 {
    gap: 4px !important;
}

.flex-gap-8 {
    gap: 8px !important;
}

.flex-gap-16 {
    gap: 16px !important;
}

.flex-gap-32 {
    gap: 32px !important;
}

.flex-gap-64 {
    gap: 64px !important;
}

.flex-gap-128 {
    gap: 128px !important;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

/* FORM */

.a-input-wrapper {
    width: 100%;
    background-color: var(--text-white-semi-faded);
    padding: 8px;
    border-radius: 8px;
    gap: 2px;
}

.a-form-wrapper .a-input-wrapper {
    margin-bottom: 8px;
}

.a-input-wrapper label {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
    margin-bottom: 8px;
}

.a-input-wrapper .flex-row>label {
    margin-bottom: 0;
}

.a-input-hint {
    font-size: var(--font-size-sm);
    color: var(--text-black-faded);
}

.a-select {
    width: 100%;
}

/* GENERAL */

.tl-curve {
    height: 50px;
    width: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    z-index: 1;
    bottom: -50px;
    border-bottom-right-radius: 50%;
}

.fit-content {
    width: fit-content;
}

.fit-container {
    width: 100%;
    height: 100%;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.width-100 {
    width: 100%;
}

.width-75 {
    width: 75%;
}

.width-50 {
    width: 50%;
}

.width-45 {
    width: 45%;
}

.width-30 {
    width: 30%;
}

.width-auto {
    width: auto;
    min-width: 40%;
}

.width-px-250 {
    width: 250px;
}

.padding-8 {
    padding: 8px;
}

.padding-inline-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.no-padding {
    padding: 0;
}

.padding-16 {
    padding: 16px;
}

.padding-16-0 {
    padding: 16px 0;
}

.padding-64-0 {
    padding: 64px 0;
}

.padding-32 {
    padding: 32px;
}

.padding-bottom-8 {
    padding-bottom: 8px;
}

.padding-top-8 {
    padding-top: 8px;
}

.relative {
    position: relative;
}

/* all child elements get a bottom border (same as flex-gap) */
.box-gap>* {
    margin-bottom: 8px;
}

.box-gap-16>* {
    margin-bottom: 16px;
}

.box-text,
.box-text-red,
.box-text-yellow,
.box-text-green {
    padding: 4px;
    border-radius: 4px;
}

.box-text {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.box-text-red {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
}

.box-text-yellow {
    background-color: rgba(255, 135, 0, 0.25);
    border: 1px solid rgba(255, 135, 0, 0.5);
}

.box-text-green {
    background-color: rgba(0, 255, 0, 0.15);
    border: 1px solid rgba(0, 255, 0, 0.3);
}

.faded-text-small {
    color: var(--text-black-faded);
    font-size: 0.65rem;
}

.icon-faded {
    color: var(--icon-black-faded);
}

.icon-faded:hover {
    color: var(--icon-black-hover);
    cursor: pointer;
}

.icon {
    color: var(--icon-black);
    transition: 0.2s;
}

.icon:hover {
    color: var(--main-red);
    cursor: pointer;
    transition: 0.2s;
}

.icon-default {
    color: var(--pill-default);
}

.icon-visible {
    color: var(--pill-green);
}

.icon-hidden {
    color: var(--pill-orange);
}

.icon-danger {
    color: var(--pill-red);
}

.red-icon {
    color: var(--main-red);
    cursor: pointer;
}

.white-icon {
    color: var(--text-white);
    cursor: pointer;
}

.bg-blur {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--bg-dark);
    z-index: 1;
    transition: 0.5s;
}

.bg-blur-hide {
    width: 0;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: var(--bg-dark);
    z-index: 1;
    transition: 0.5s;
}

.red-link {
    color: var(--main-red);
}

.red-link:hover {
    color: var(--main-red-hover);
    cursor: pointer;
    text-decoration: underline;
}

img.thumbnail {
    max-height: 100px;
    object-fit: unset;
    width: auto;
    height: auto;
    margin: 8px;
}

.margin-8 {
    margin: 8px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

/* FORMS */

form {
    width: 100%;
}

select option {
    font-family: var(--font-family-sans-serif);
    font-display: swap;
}

select,
input,
textarea {
    border: 1px solid var(--divider-black);
    border-radius: 4px;
    padding: 4px;
    width: 100%;
    transition: 0.2s;
}

input,
textarea,
select {
    --_input-color: hsl(0, 0%, 80%);
    border: 1px solid var(--_input-color);

}

input:invalid,
textarea:invalid,
select:invalid {
    --_input-color: var(--pill-orange);
}

input:focus,
textarea:focus,
select:focus {
    --_input-color: var(--dark-red);
}

.success-box {
    padding: 8px 16px;
    background-color: var(--success-light);
    border: 1px solid var(--success);
    border-radius: 4px;
}

/* BUTTONS */

.button-link,
.button-outlined,
.button-contained {
    height: fit-content;
    width: fit-content;
    padding: 8px 16px;
    background-color: var(--main-red);
    border: none;
    color: var(--text-white);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
    transition: 0.25s;
}

.button-outlined {
    background-color: transparent;
    border: 1px solid var(--main-red);
    color: var(--text-dark);
}

.button-link {
    background-color: transparent;
    border-color: transparent;
    color: var(--text-dark);
    padding-top: 0;
    padding-bottom: 0;
}

.button-outlined:hover,
.button-contained:hover {
    cursor: pointer;
    background-color: var(--main-red-hover);
}

.button-outlined:hover {
    color: var(--text-white);
}

.button-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.button-outlined:disabled,
.button-contained:disabled {
    background-color: var(--red-disabled);
}

.dark-red-btn {
    background-color: var(--dark-red);
}

.dark-red-btn:hover {
    background-color: var(--dark-red-hover);
}

.dark-red-btn:disabled {
    background-color: var(--red-disabled);
    cursor: no-drop;
}

.button-100 {
    width: 100%;
}

/* DIVIDERS */

.divider-line-white {
    border-top: 1px solid var(--divider-white);
    width: 100%;
}

.divider-line-black {
    border-top: 1px solid var(--divider-black);
    width: 100%;
}

.divider-line-bottom-black {
    border-bottom: 1px solid var(--divider-black);
    width: 100%;
}

.divider-line-red {
    border-top: 2px solid var(--main-red);
    width: 100%;
}

.white-gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%);
}

.white-gradient-flipped {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255) 100%);
}

.left-arrow {
    position: absolute;
    width: 300px;
    height: 100%;
    z-index: 5;
}

.left-arrow-gradient {
    position: absolute;
    width: 150px;
    height: 100%;
    left: 0;
    z-index: 5;
}

.right-arrow-gradient {
    position: absolute;
    width: 150px;
    height: 100%;
    right: 0;
    z-index: 5;
}

.ra-inner-container {
    width: 100%;
    height: 100% !important;
}

.right-arrow {
    height: 100%;
    z-index: 5;
    position: absolute;
    right: 0;
}

.l-r-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-right: 0;
    margin-left: 0;
    z-index: 5;
}

.carousel-container {
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
}

.row-section {
    margin: 8px 8px;
    padding: 16px 16px;
}

@media screen and (min-width: 1200px) {
    .row-section {
        padding: 0 10% 16px 10%;
    }
}

/* PAGE STYLES */

.page-wrapper {
    padding: 0;
    padding-right: 0 !important;
    width: 100%;
    flex-grow: 1;
}

.content-section-wrapper,
.content-section-wrapper-sm {
    margin: 0 auto 64px auto;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.content-section-wrapper {
    max-width: 1440px;
}

.content-section-wrapper-sm {
    max-width: 768px;
}

.exta-info-wrapper {
    max-width: 992px;
    margin: 0 auto;
    padding: 8px 32px 8px 32px;
    line-height: 1.618rem;
}

@media screen and (min-width: 992px) {
    .content-section-wrapper-sm {
        padding: 16px 0;
    }
}

@media screen and (min-width: 1200px) {
    .page-wrapper {
        padding: 0;
        max-width: 1920px;
        margin: 0 auto;
    }
}

.disable-drag {
    pointer-events: none;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-top-32 {
    margin-top: 32px;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.fullscreen-overlay-clear {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 8;
    background-color: transparent;
}

/* TEXT */

.text-small {
    font-size: var(--font-size-sm);
}

.text-align-center {
    text-align: center !important;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-sm {
    font-size: var(--font-size-sm);
}

.text-xs {
    font-size: var(--font-size-xs);
}

.text-small-print {
    font-size: var(--font-size-small-print);
    line-height: 1rem;
}


.fw-normal {
    font-weight: normal;
}

.text-wrap-balance {
    text-wrap: balance;
}

.light-text {
    color: var(--text-black-faded);
}

.resize-textarea {
    resize: vertical;
}

.red-text {
    color: var(--main-red);
}

.text-black-faded {
    color: var(--text-black-faded);
}

/* IMAGES */
.aspect-ratio-4x3,
.aspect-ratio-1x1,
.aspect-ratio-16x9,
.aspect-ratio-3x1,
.aspect-ratio-4x1 {
    object-fit: cover;
    width: 100%;
    height: auto;
}

.aspect-ratio-1x1 {
    aspect-ratio: 1/1;
}

.aspect-ratio-16x9 {
    aspect-ratio: 16/9;
}

.aspect-ratio-3x1 {
    aspect-ratio: 3/1;
}

.aspect-ratio-4x1 {
    aspect-ratio: 4/1;
}

.aspect-ratio-4x3 {
    aspect-ratio: 4/3;
}

/* BREADCRUMBS */

.breadcrumb-wrapper {
    padding: 0 16px 16px 16px;
}

.breadcrumb-wrapper ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumb {
    font-size: var(--font-size-sm);
    color: var(--text-black-faded);
    display: inline;
}

.breadcrumb:hover {
    color: var(--text-black);
}

.breadcrumb-text {
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
}

.breadcrumb:first-child {
    padding-left: 0;
}

.breadcrumb:last-child {
    color: var(--main-red);
    font-weight: var(--font-weight-bold);
}

.breadcrumb:last-child:hover {
    color: var(--dark-red-hover);
}

.breadcrumb::after {
    content: "/";
}

.breadcrumb:last-child::after {
    content: "";
}

/* FILTER */

.filter-module-wrapper {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
}

.filter-button {
    background-color: var(--main-red);
    color: var(--text-white);
    padding: 8px 16px;
    font-size: var(--font-size-sm);
    border-radius: 16px;

    width: fit-content;
    position: relative;
}

.filter-button:hover {
    cursor: pointer;
}

/* MARKDOWN CLASSES */

.markdown p {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0.5em;
    white-space: normal;
}

.markdown blockquote p,
.markdown code p,
.markdown li p {
    margin-bottom: unset;
}

.markdown blockquote p:first-child,
.markdown code p:first-child,
.markdown li p:first-child,
.markdown p:first-child {
    padding-top: 0;
}

.markdown blockquote p:last-child,
.markdown code p:last-child,
.markdown li p:last-child,
.markdown p:last-child {
    padding-bottom: 0;
}

.markdown a {
    color: var(--main-red);
    transition: 0.15s;
}

.markdown a:hover {
    color: var(--main-red-hover);
}

.markdown blockquote {
    border-left: solid 4px var(--main-red);
    padding: 4px 0 4px 8px;
    font-weight: var(--font-weight-bold);
}

.markdown code {
    font-family: var(--font-family-sans-serif);
    font-display: swap;
    color: var(--text-white);
    background-color: var(--main-red);
    font-weight: var(--font-weight-bold);
    padding: 0.5ch;
}


/* END OF MARKDOWN */

/* MEDIA QUERIES */

.visible-sm {
    display: none;
}

.hidden {
    display: none;
}

@media screen and (max-width: 992px) {

    /* screens smaller than 992px */
    .full-width-md {
        width: 100%;
    }

    .visible-md {
        display: unset;
    }

    .hidden-md {
        display: none;
    }

    .flex-column-md {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {

    /* screens smaller than 768px */
    .full-width-sm {
        width: 100%;
    }

    .visible-sm {
        display: unset;
    }

    .hidden-sm {
        display: none;
    }

    .flex-column-sm {
        flex-direction: column;
    }
}