.product-page-wrapper {
    padding: 16px;
    max-width: 1920px;
    width: 100%;
    flex-grow: 1;
}

.product-carousel-container {
   width: 100%;
   max-width: 100vw;/* jic */
   box-sizing: border-box;
   padding-bottom: 60px;  
}
.product-carousel-item {
    box-sizing: border-box;
    width: 100%;
    max-height: 500px; 
}
.product-carousel-button-group {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    right: 0;
}
.product-carousel-popup img {
    margin: 0 auto;
}

.hp-product-row-wrapper {
    width: 100%;    
    padding-left: 0;
}
.hp-product-rows-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
}

.p-inner-wrapper {
    display: grid;
    grid-template-columns: 100%;     
    grid-gap: 16px;   
    align-items: start;
    justify-content: center;
    width: 100%;
    max-width: 100vw;/* jic */ 
    padding-left: 16px; 
    padding-right: 16px;   
}

.p-inner-wrapper > div {
    padding-left: 8px; 
    padding-right: 8px; 
}

.p-inner-wrapper h1 {
    line-height: initial;
}

.p-image {    
    width: 100%;
    height: 100%;    
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-image img {
    border-radius: 32px 0 32px 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
}

.p-mini-image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 4px;
    transition: 0.2s;
    border: 1px solid rgba(0, 0, 0, 0);
}
.p-mini-image:hover {
    cursor: pointer;
}
.selected-mini-img {
    border: 1px solid var(--divider-black);
    transition: 0.2s;
}
.selected-mini-img img {
    filter: contrast(35%);
    transition: 0.2s;
}

.p-variant-name {
    font-size: var(--font-size-normal);
    color: var(--text-black-faded);
    display: block;
}
@media screen and (min-width: 768px) {
    .p-variant-name {
        display: unset;
    }
}

.p-name {
    line-height: 1.618rem;
}

.p-description {
    font-size: var(--font-size-sm);
    color: var(--text-black-faded);
    line-height: 1.618rem;
    word-wrap: break-word;
}

.p-mobile-variants-wrapper {
    display: block;
    margin-top: 12px;
}
.p-variants-wrapper {
    width: 100%;
    margin-top: 8px;
    display: none;
}

@media screen and (min-width: 768px) {
    .p-mobile-variants-wrapper {
        display: none;
    }
    .p-variants-wrapper {
        display: flex;
    }
}
.p-variant {
    border: 1px solid var(--divider-black);
    width: fit-content;
    padding: 4px 6px;
    font-size: var(--font-size-xs);
    border-radius: 4px;
}
.p-variant:hover {
    cursor: pointer;
    background-color: var(--main-red);
    color: var(--text-white)
}
.p-variant-selected {
    border: 1px solid var(--main-red);
}

.pv-image {
    width: 16px;
    height: 16px;
}

.red-serif {
    font-family: var(--font-family-serif);
    font-display: swap;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--main-red);
    line-height: 18px;
}

.serif {
    font-family: var(--font-family-serif);
    font-display: swap;
}

.sans-serif {
    font-family: var(--font-family-sans-serif);
    font-display: swap;
}

.p-flie-wrapper {
    align-items: stretch;
    padding-bottom: 16px;
}

.p-file {
    --_file-icon-color: var(--icon-black-faded);
    --_file-border: var(--divider-black);
    --_file-text-color: var(--text-black-faded);

    border: 2px dashed var(--_file-border);
    border-radius: 8px;
    transition: 0.2s;
    align-items: center;
    justify-content: start;
    gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.p-file-name {    
    color: var(--_file-text-color);
    padding: 8px;
    transition: 0.2s;
}

.p-file svg {    
    color: var(--_file-icon-color);
    transition: 0.2s;
}

.p-file:hover {
    --_file-border: var(--main-red);
    --_file-text-color: var(--main-red);
    --_file-icon-color: var(--main-red);
}

.p-tags p {
    width: fit-content;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
    color: var(--text-black-semi-faded);
}

.sale-price div:last-child p {
    font-size: var(--font-size-normal);
    color: var(--text-black-faded);
    text-decoration: line-through;
}
.sale-price div:last-child p b {
    text-decoration: none !important;
}
.standard-price  div p,
.sale-price div p {
    font-size: var(--font-size-2xl);
    text-decoration: none;
    color: var(--main-red);
}
.standard-price  div p b,
.sale-price div p b {
    font-size: var(--font-size-xs);
    text-decoration: none;
    color: var(--main-red);
}

.collapsable-wrapper {
    width: 100%;
    margin: 16px 0;
}

.collapsable-btn {
    width: 100%;
    margin: 0;
    padding: 8px;
    border-bottom: 2px solid var(--divider-black);
    background-color: var(--bg);
}
.collapsable-btn:hover {
    cursor: pointer;
}

.collapsable-info {
    margin: 16px;
}

.show-more-wrapper {
    margin-top: 8px;
}

@media screen and (min-width: 1200px) {
    .p-mobile-variants-wrapper {
        display: none;
    }
    .p-variants-wrapper {
        display: flex;
    }
    .product-page-wrapper {
        padding: 16px;
        margin: 0 auto;
        max-width: 1920px;
        width: 100%;
        flex-grow: 1;
    }
    .p-inner-wrapper {
        grid-template-columns: 50% 1fr;
    }
    .product-wrapper {
        max-width: 1440px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 600px) {
    .product-carousel-item {
        max-height: 500px;
    }
}
@media screen and (max-width: 768px) {
    .product-carousel-item {
        max-height: 250px;
    }
}