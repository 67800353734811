.lds-ellipsis-wrapper,
.lds-home-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;   
    margin: 8px;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;   
    width: 76px;
    height: 12px;
}
.lds-ellipsis div {
    --_dot-color: var(--main-red);
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;    
    background-color: var(--_dot-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    transition: background-color 0.8s;
}

.lds-ellipsis-white div{
    --_dot-color: white;
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.8s infinite;    
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.8s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.8s infinite;    
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.8s infinite;
}

.lds-home-wrapper {
    height: 100%;
}

.lds-home-wrapper svg {
    transform-origin: bottom center;
    animation: splash-ani 2.5s ease-in-out infinite;
    animation-direction: alternate;
    transform: rotate(25deg);
    width: 25%;
}
@media (prefers-reduced-motion) {
    .lds-home-wrapper svg {
        transform: rotate(0deg);
        animation-play-state: paused;
    }
}


@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);        
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
