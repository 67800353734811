/* SECTIONS */


.hp-range-wrapper {      
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: stretch;
    gap: 16px;
    margin: 8px 16px;
    height: auto;
}

.hp-range {
    flex: 1; 
    display: flex;
    flex-direction: column;
}

.hp-range-img-wrapper {
    width: 100%;    
}
.hp-range-img-wrapper img {
    border-top-right-radius: 8px;   
    border-top-left-radius: 8px;   
}
.hp-range-name-wrapper {    
    padding: 8px 0px;
    flex: 1;
}

.hp-range-name-wrapper h1,
.hp-range-name-wrapper h2,
.hp-range-name-wrapper h3 {
    font-size: var(--font-size-normal);
    line-height: var(--font-size-normal);
}
.hp-range-name-wrapper p {
    padding-top: 8px;
    font-size: var(--font-size-sm);
    color: var(--text-black-faded);
}


@media screen and (min-width: 768px) {

    .hp-range-wrapper {           
        flex-direction: row;
        margin: 16px 24px;
    }

    .hp-range-img-wrapper {
        height: 200px;        
        overflow: hidden;
    }

    .hp-range-img-wrapper img{       
      object-fit: cover;  
      width:100%;
      height: 100%;
      object-position: center;
    }  
}

@media screen and (min-width: 992px) {
    .hp-range-img-wrapper {
        height: 350px;    
    }
}

@media screen and (min-width: 1200px) {

    .hp-range-img-wrapper {
        height: 480px; /* 80% of 600px */   
    }

    .hp-range-wrapper {
        margin: 32px 48px; 
        gap: 32px;    
    }

    .hp-range-name-wrapper h1,
    .hp-range-name-wrapper h2,
    .hp-range-name-wrapper h3 {
        font-size: var(--font-size-lg);
        line-height: var(--font-size-normal);
    }
  
}