.popup-card-wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: var(--bg-overlay);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-card {
    padding: 16px;
    background-color: var(--main-red);
    color: var(--text-white);
    width: fit-content;
    border-radius: 16px;
}
.white-btn {
    height: fit-content;
    width: fit-content;
    padding: 8px 16px;
    background-color: #fff;
    border: none;
    color: #000;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
}

.white-btn:hover {
    cursor: pointer;
}

.white-btn-text {
    height: fit-content;
    width: fit-content;
    padding: 8px 16px;
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.75);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
}
.white-btn-text:hover {
    cursor: pointer;
}
