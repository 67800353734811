.product-card-wrapper {
    border-radius: 4px;
    margin-left: 8px;
    margin-right: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: var(--box-shadow-light);
}

.pc-image {
    width: 100%;
    min-height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pc-image img {
    transition: 0.2s;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.subcat-pill,
.offer-pill {
    padding: 4px 6px;
    background-color: var(--main-red-hover);
    position: absolute;
    color: var(--text-white);
    font-size: 0.675rem;
    border-radius: 8px;
    font-weight: var(--font-weight-bold);
}

.subcat-pill {
    top: 8px;
    left: 8px;
}

.offer-pill {
    --_pill-color: var(--main-red);
    margin: 0;
    border-radius: 0;
    padding: 0.5rem 0.25rem;
    top: 0;
    right: 0;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
    background: var(--_pill-color);
}

.offer-pill:before,
.offer-pill:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0;
    /* tweak */
    width: 200%;
    height: 100%;
    background: var(--_pill-color);
}

.offer-pill:before {
    right: 100%;
}


.pc-name p {
    font-family: var(--font-family-serif);
    font-display: swap;
    font-size: var(--font-size-normal);
    font-weight: var(-font-weight-light);
    line-height: 1.618rem;
    padding: 2px 0;
    text-align: left;
}

.pc-vname {
    color: var(--text-black-faded);
    font-size: var(--font-size-sm);
}

.pc-tags p {
    width: fit-content;
    font-size: 0.5rem;
    font-weight: var(--font-weight-bold);
    color: var(--text-black-semi-faded);
}

.products-row {
    width: fit-content;
    flex-wrap: nowrap;
}

.products-row-swipe {
    overflow-x: hidden;
    overflow-y: hidden;
    width: fit-content;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

.product-section-wrapper {
    --_column-count: 1;
    max-width: 1440px;
    width: 100%;
    margin: 8px auto;
    /*padding: 0 16px;*/
    display: grid;
    justify-content: space-evenly;
    justify-items: stretch;
    align-items: stretch;
    grid-template-columns: repeat(var(--_column-count), 1fr);
    grid-gap: 8px;
    /*shortand for grid-column-gap & grid-row-gap */
}

.product-section-wrapper .product-card-wrapper {
    /* unset the height when in a grid as it uses align-items:stretch */
    height: auto;
}

.carousel-container .product-card-wrapper {
    height: 100%;
}

.brand-logo {
    height: auto;
    width: 64px;
}

.product-card-info {
    padding: 8px;
    width: 100%;
    flex: 1;
    justify-content: space-between;
}

.product-card-extra-info-center,
.product-card-extra-info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-regular);
    color: var(--text-black-faded);
    flex-direction: column;
    gap: 16px;
}

@media screen and (min-width: 1200px) {
    .product-card-extra-info {
        flex-direction: row;
        justify-content: space-between;
    }
}

.product-card-extra-info-center {
    align-items: center;
}

.product-card-price {
    font-family: var(--font-family-serif);
    font-display: swap;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    color: var(--main-red);
}

.product-card-sales-price {
    color: var(--main-red);
}

.product-brand-name {
    width: fit-content;
    max-height: 32px;
    display: flex;
    justify-content: flex-start;
}

.product-brand-name p {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    color: var(--text-black-faded);
}

.more-options-image {
    width: fit-content;
    height: 24px;
}

.more-options-image img {
    height: 24px;
}

.immediate-delivery,
.immediate-delivery img {
    height: 24px;
}

.hp-carousel-item {
    box-sizing: border-box;
}

.carousel-item {
    box-sizing: border-box;
}

.hp-prod-margin-left:first-child {
    margin-left: 0;
}

@media screen and (min-width: 1200px) {
    .pc-image {
        height: 250px;
    }
}

@media screen and (min-width: 768px) {
    .product-section-wrapper {
        --_column-count: 2;
        /*grid-template-columns: 1fr 1fr;*/
        grid-gap: 16px;
    }

    .pc-name p {
        font-size: var(--font-size-lg);
    }
}

@media screen and (min-width: 992px) {
    .product-section-wrapper {
        --_column-count: 3;
        /*grid-template-columns: 1fr 1fr 1fr;*/
        grid-gap: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .product-section-wrapper {
        --_column-count: 4;
        /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
        grid-gap: 24px;
    }
}