:root {
    /* BREAKPOINTS */

    --mobile: 600px;
    /*max*/
    --tablet: 600px;
    /*min*/
    --landscapeTablet: 768px;
    /*min*/
    --desktop: 992px;
    /*min*/
    --desktopWide: 1200px;
    /*min*/

    /* COLOURS */

    --light-grey: rgba(0, 0, 0, 0.3);

    --overlay-grey: rgba(55, 55, 55, 0.5);

    --bg: rgb(255, 255, 255);
    --bg-dark: rgba(0, 0, 0, 0.2);
    --bg-overlay: rgba(0, 0, 0, 0.5);
    --bg-black-faded: rgba(0, 0, 0, 0.1);
    --bg-black: #000000;

    --text-pure-white: rgb(255, 255, 255);
    --text-white: rgba(235, 235, 235, 1);
    --text-white-faded: rgba(235, 235, 235, 0.85);
    --text-white-semi-faded: rgba(235, 235, 235, 0.651);
    --text-black: rgba(55, 55, 55, 1);
    --text-black-faded: rgba(55, 55, 55, 0.75);
    --text-black-semi-faded: rgba(55, 55, 55, 0.65);

    --success: #15ca15;
    --success-light: rgb(185, 255, 185);

    --main-red: #e30613;
    --main-red-hover: #ce0715;
    --main-red-faded: rgba(229, 6, 21, 0.65);
    --red-disabled: rgb(255, 136, 144);

    --main-blue: #007ac0;
    --main-blue-hover: #0060a3;

    --dark-red: rgb(155, 0, 0);
    --dark-red-light: rgba(155, 0, 0, 0.1);
    --dark-red-hover: rgba(100, 0, 0, 1);

    --red-variant: rgba(155, 0, 0, 0.3);

    --icon-black: rgba(0, 0, 0, 0.8);
    --icon-black-faded: rgba(0, 0, 0, 0.4);
    --icon-black-hover: rgba(0, 0, 0, 0.75);

    --drop-shadow: rgba(0, 0, 0, 0.1);

    --box-shadow-light: 0px 2px 1px -1px rgba(127, 127, 127, 0.2),
        0px 1px 1px 0px rgba(127, 127, 127, 0.14),
        0px 1px 3px 0px rgba(127, 127, 127, 0.12);

    --box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    --divider-white: rgba(255, 255, 255, 0.2);
    --divider-black: rgba(0, 0, 0, 0.2);
    --divider-red: rgba(155, 0, 0, 0.2);

    --pill-green: rgba(0, 155, 0, 1);
    --pill-orange: rgb(241, 121, 22);
    --pill-red: rgb(155, 0, 0);
    --pill-default: var(--icon-black-faded);

    --interest-free-blue: var(--main-blue);

    --font-family-sans-serif: "Public Sans", sans-serif;
    --font-family-serif: "Lora", serif;

    /* Ration: 1:1.2
    --font-size-huge: 10.699rem;
    --font-size-5xl: 2.986rem; 
    --font-size-4xl: 2.488rem;
    --font-size-3xl: 2.074rem;    
    --font-size-xs: 0.694rem;
    */
    --font-size-4xl: 2.75rem;
    --font-size-3xl: 1.875rem;
    --font-size-2xl: 1.75rem;
    --font-size-xl: 1.5rem;
    --font-size-lg: 1.25rem;
    --font-size-normal: 1rem;
    --font-size-sm: 0.875rem;
    --font-size-xs: 0.75rem;
    --font-size-small-print: 0.625rem;

    --font-weight-light: 200;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-black: 900;
}

/*
p,
h1,
h2,
h3,
h4,
h5,
a,
b,
td,
textarea,
input {
    font-family: "Comic Sans MS" !important;
}
*/