.heading-wrapper {
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.heading-wrapper h2,
.heading-wrapper h3 {
    line-height: var(--font-size-xl);
    font-size: var(--font-size-xl);    
}
.heading-wrapper p {
    line-height: 16px;
    font-size: var(--font-size-sm);
    color: var(--text-black-semi-faded);
}

.section-heading-wrapper {
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}
.section-heading-header {
    line-height: 22px;
    font-size: var(--font-size-xl) !important;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-family-serif);
    font-display: swap;
    transition: 0.15s;
}
a.section-heading-header:hover {
    color: var(--main-red);
    transition: 0.15s;
}

.section-heading-wrapper p {
    line-height: 16px;
    font-size: var(--font-size-xs);
    color: var(--text-black-semi-faded);
}

.section-heading-wrapper h2,
.section-heading-wrapper h3 {
    margin: 0;
    padding: 0;
}

.section-heading-wrapper h2 {
    font-family: var(--font-family-sans-serif);
}

@media screen and (min-width: 1200px) {
    .heading-wrapper {
        padding: 8px 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .section-heading-wrapper {
        padding: 16px 32px;
        gap: 8px;
        text-align: center;
    }
}

.section-heading-cat {
    color: var(--main-red) !important;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xs);
}
