
.brand-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
}

.brand-header-wrapper div p {
    font-size: var(--font-size-sm);
}

.brand-header-logo {
    width: 150px;
    max-width: 150px;
    max-height: 150px;
    overflow: hidden;
    height: auto;
    align-items: center;
    border-radius: 4px;
}

.brand-header-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (min-width: 768px) {
    .brand-header-wrapper {
        flex-direction: row;
        align-items: unset;
    }
    .brand-header-logo {
        width: 250px;
        max-width: 250px;
    }
}