/*
.carousel-style - .carousel-style-1..3	
    .carousel-content-wrapper
	    .carousel-bg-wrapper | carousel-bg-gradient
		    img
        .carousel-cta-wrapper (hgroup > h3, p, a)
*/

.carousel-style,
.carousel-large-container {
    height: 300px;   
    width: 100%;
}

/* Large Screen */
@media screen and (min-width: 1200px) {
    .carousel-large-container,
    .carousel-style {    
       height: 500px;
    }
}

.carousel-style {   
    --_bg-image: linear-gradient(-45deg, var(--red-disabled) 0%, var(--bg) 100%); /*none;*/

    position: relative; 
    overflow: hidden;
    display: flex;
    gap: none !important;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;    
}

/* Blured background image */
.carousel-style::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: var(--_bg-image);
    width: 100%;
    height: 100%;
    filter: blur(20px) grayscale(50%) opacity(85%);
}

.carousel-content-wrapper {
    display: grid;
}
.carousel-content-wrapper > * {
    grid-row: 1;
    grid-column: 1;
}
.carousel-style .carousel-cta-wrapper {
    color: var(--text-white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px;
    gap: 16px;
}

.carousel-style-2 .carousel-cta-wrapper {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.carousel-style-3 .carousel-cta-wrapper {
    display: none;
}

.carousel-style .carousel-bg-gradient::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    display: inline-block;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.35) 45%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);; /* IE6-9 */
}

.carousel-style-2 .carousel-bg-gradient::after {
    background: rgba(0, 0, 0, 0.35);
}

.carousel-style .carousel-bg-wrapper img {
    object-fit: contain;
    object-position: center; 
    max-width: 100%;
    height: 300px;     
}

/* Large Screen */
@media screen and (min-width: 1200px) {
    .carousel-style .carousel-bg-wrapper img  {    
       height: 500px;
    }
}

/*********************** .carousel-style ************************/

.carousel-style .carousel-cta-wrapper {
    z-index: 25;
}

.carousel-style .carousel-cta-wrapper h3 {
    font-size: var(--font-size-3xl);
    line-height: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

.carousel-style .carousel-cta-wrapper p {
    font-size: var(--font-size-xs);
    line-height: var(--font-size-normal);
    font-weight: var(--font-weight-light);
    color: var(--text-white-faded);
    width: 75%;
}

/*********************** other styles ************************/

.carousel-large-wrapper {
    width: 100%;
    border-radius: 0 0 32px 0;
    overflow: hidden;
}

.carousel-large-item {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .carousel-large-dot li button {
        height: 8px;
        width: 24px;
        border-radius: 0;        
        margin-right: 12px;
        margin-bottom: 8px;
    }
}

.react-multi-carousel-dot button {
    background: var(--main-red-faded) !important;
    border-color: transparent !important;
}
.react-multi-carousel-dot--active button {
    background: var(--main-red) !important;
}

.react-multiple-carousel__arrow {
    z-index: 50 !important;
}

.a-carousel-table-wrapper {
    width: fit-content;
}

.a-table-carousel-title {
    width: 30%;
    padding: 16px 64px;
    text-align: center;
}

/* Large Screen */
@media screen and (min-width: 1200px) {

    .carcarousel-style .carousel-cta-wrapper {
        padding: 64px;
        gap: 16px;
    }    
    .carousel-style-2 .carousel-cta-wrapper {
        padding: 32px;
    }

    /*********************** .carousel-style ************************/
    .carousel-style .carousel-cta-wrapper h3 {
        font-size: var(--font-size-4xl);
        line-height: var(--font-size-2xl);
    }
    .carousel-style .carousel-cta-wrapper p {
        font-size: var(--font-size-normal);
        font-weight: var(--font-weight-medium);
    }
    .carousel-large-wrapper {
        border-radius: 0 0 64px 0;       
    }   
}
