#find-us {
    width: 100%;
}

.find-us-wrapper-column {
    display: flex;
    flex-direction: column;
}

.find-us-wrapper-row {
    display: flex;
    flex-direction: column;  
}

.find-us-item {
    margin-top: 16px;
    flex-basis: 170px;
}
.fu-contact {    
    flex-basis: 140px;
}

.find-us-content,
.find-us-content h1,
.find-us-content h2 {
    padding: 4px 24px;
    font-size: var(--font-size-xs);
    line-height: var(--font-size-sm);
    padding:0;
    font-family: var(--font-family-sans-serif);
    font-weight: var(--font-weight-regular);
}

.find-us-content h1,
.find-us-content h2 {
    font-weight: var(--font-weight-bold); 
}

.find-us-content > * {
    margin-bottom: 4px;
}

.find-us-map {
    margin-top: 32px;
    height: 400px;
}

.find-us-map iframe {
    border: 1px solid var(--divider-black);
}

.fu-address {
    display: flex;
    flex-direction: column;
    gap: 4px !important;
    font-weight: var(--font-weight-medium);   
}

.fu-days {
    font-size: var(--font-size-xs);
    color: var(--text-black);
    font-weight: var(--font-weight-bold);
}
.fu-times {
    font-size: var(--font-size-xs);
}
.fu-left {
    gap: 8px;
    margin-left: 16px;
    margin-right: 16px;
}

.red-subtitle h2,
.red-subtitle {
    color: var(--main-red);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    gap: 0;
}

.fu-contact-text {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
}

@media screen and (min-width: 1200px) {
    .find-us-wrapper-column {
        display: flex;
        flex-direction: row;      
    }
    .fu-left {
        gap: 32px;
        padding: 16px;        
        margin-left: 0;
        margin-right: 0;
    }

    .red-subtitle h2,
    .red-subtitle {
        color: var(--main-red);
        font-size: var(--font-size-lg);
        font-weight: var(--font-weight-bold);
        gap: 8px;
    }

    .fu-days {
        font-size: var(--font-size-sm);
        color: var(--text-black);
        font-weight: var(--font-weight-medium);
    }
    .fu-times {
        font-size: var(--font-size-sm);
    }
    .fu-contact-text {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-medium);
    }

    .find-us-content > * {
        margin-bottom: 0;
    }

    .find-us-content,
    .find-us-content h1,
    .find-us-content h2 {  
        font-size: var(--font-size-sm);
        line-height: var(--font-size-normal);
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .find-us-map {
        margin-top: 32px;
        height: 500px;        
    }

    .find-us-map iframe {
        border: 1px solid var(--divider-black);
    }

    .find-us-item {
        margin-top: 16px;
        flex-basis: auto;
    }

    
    .find-us-wrapper-column .fu-left {
        width: 20%;
    }    
    .find-us-wrapper-column .find-us-map {
        width: 80%;
    }    
}
