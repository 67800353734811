.navbar-wrapper {
    width: 100vw;
    height: 80px;
    box-shadow: 0 2px 4px var(--drop-shadow);
    position: sticky;
    z-index: 99;
}
.navbar-top-mobile {
    display: block;
    height: 100%;
    padding: 24px;
    z-index: 5;
}
.navbar-top-desktop {
    display: none;
    height: 0%;
}
.navbar-bottom-wrapper {
    display: none;
    height: 0%;
}
.navbar-bottom {
    display: none;
    height: 0%;
}

.nb-dropdown {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
}
.nb-dropdown-hidden {
    display: none;
    height: 0;
    width: 0;
    overflow: hidden;
}

.logo-image-wrapper {
    flex: 0.5;
    text-align: right;
}

.logo-image-wrapper img {
    height: 100%; 
    display: inline-block;  
}

.popout-nav-wrapper {
    width: fit-content;
    height: calc(100vh - 80px);
    position: absolute;
    background-color: var(--bg);
    left: 0;
    top: 80px;
    bottom: 0;
    right: 0;
}

.popout-nav {
    width: 300px;
    position: absolute;
    background-color: var(--bg);
    height: 100%;
    padding: 0;
    margin: 0;
    left: 0;
    z-index: 5;
    transition: 0.5s;
    display: flex;
}

.popout-nav-hide {
    width: 300px;
    position: absolute;
    background-color: var(--bg);
    height: 100%;
    left: -300px;
    z-index: 5;
    transition: 0.5s;
}

.mobile-search-wrapper {
    position: relative;
    
}

.search-input {
    border: none !important;
    border-radius: 0;
    margin-left: 28px;
}
.search-icon {
    color: var(--icon-black);
    position: absolute;
    left: 0;
    transition: 0.5s;
}
.search-input:focus + .search-icon {
    color: var(--main-red);
    transition: 0.5s;
}

input:focus {
    transition: 0.2s;
}

.bottom-search-border {
    height: 1px;
    width: 0%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--main-red);
    z-index: 1;
    transition: 0.5s;
}

.search-input:focus ~ .bottom-search-border {
    height: 1px;
    width: 100%;
    transition: 0.5s;
}

.m-opening-times {
    border-bottom: 1px solid var(--main-red);
}

.m-section-header {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
}

.m-contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-contact-wrapper a {
    text-align: center;
}

.m-contact-text {
    font-size: var(--font-size-sm);
    color: var(--main-red);
    font-weight: var(--font-weight-bold);
    line-height: 12px;
}

.m-category {
    padding: 16px;
    border-top: 1px solid var(--divider-black);
    background-color: #fff;
}
.m-category:first-child {
    border-top: none;
}
.m-category-special-offers {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    color: var(--main-blue);
}
.m-cat-wrapper {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;    
}
.m-nav-contact {
    padding: 8px;
    position: sticky;
    bottom: 0;
    border-top: 1px solid var(--main-red);
    background-color: var(--bg);
}

.m-popout-content {
    display:flex;
    justify-content:space-between;
    flex: 1;
    margin-bottom: 120px;
}

.m-subcat-wrapper {
    max-height: 650px;
    transition: 0.5s ease-out;
}
.m-subcat-wrapper-closed {
    max-height: 0px;
    transition: 0.5s ease-out;
}

.m-subcat {
    padding: 8px;
    padding-left: 24px;
    border-top: 1px solid var(--divider-black);
    background-color: var(--main-red);
    transition: 0.3s;
    color: var(--text-white);
}

.ot-days {
    font-size: var(--font-size-normal);
    color: var(--main-red);
    font-weight: var(--font-weight-bold);
    line-height: 12px;
}
.ot-times {
    font-size: var(--font-size-sm);
}

/*
# Nav bar
*/

.navbar-range-wrapper {
    width: 300px;  
    height: 180px;  
    overflow: hidden;
}
.navbar-range {
    width: 100%;
    display: grid;
    grid-template-rows: 150px 1fr;   
}

.navbar-range-wrapper img {
    border-radius: 8px;
    margin: 0;
    object-fit: cover;
    width: 100%; 
    height: 100%;
}
.navbar-range-wrapper img.navbar-range-default-img {
    object-fit: scale-down;
    background-color: var(--interest-free-blue);
}

.navbar-range-wrapper h3 {
    flex:1;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
    margin-top: 0;
    padding-top: 0;
}

.nb-brand-title { 
    padding: 0; 
}

@media screen and (min-width: 1200px) {
    .navbar-wrapper {
        height: 150px !important;
        width: 100%;
    }
    .navbar-top-mobile {
        height: 0%;
        padding: 0;
        display: none;
        overflow: hidden;
    }
    .navbar-top-desktop {
        display: block;
        height: 65%;
        padding: 16px 64px;
        position: relative;
    }
    .navbar-bottom-wrapper {
        display: initial;
        height: 100%;
    }
    .navbar-bottom {
        background-color: var(--main-red);
        position: relative;
        height: 35%;
        padding: 0 ;
        display: grid;
        
        grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
        grid-area: header;
        column-gap: 8px;
        justify-items: stretch;
        align-items:stretch;
        margin-top: 0;
        margin-bottom: 0;
       
    }
    .red-corner {
        height: 64px;
        width: 64px;
        position: absolute;
        bottom: -64px;
        left: 0;
        z-index: 6;
        pointer-events: none;
    }

    .logo-wrapper {
        flex-grow: 1;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
    }
    .logo-wrapper img {
        min-width: 200px;
        max-width: 200px;
        height: 100%;
        object-fit: contain;
    }

    .nb-left,
    .nb-right {
        flex: 0.5;
    }
    .nb-right {
        justify-content: right;
        display: flex;
    }
    .nb-right form {
        width: unset;
        display: inline-block;
    }

    .nav-contact {
        padding: 8px;
    }

    .ot-days {
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-regular);
    }
    .bottom-search-border {
        width: 100%;
        max-width: 300px;
    }

    .nb-cat-button {
        color: var(--text-white);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-sm);
        margin: 0;
        padding: 0;
        display: flex;
    }

    .nb-cat-button-link {
        width: 100%;        
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; 
    }
    .nb-cat-button-link,
    .nb-cat-button-link:visited,
    .nb-cat-button-link:active {            
        color: var(--text-white);       
    }
    .nb-cat-button-link:hover {
        color: var(--text-white);
    }
    .nb-cat-button-special-offers,
    .nb-cat-button-special-offers:visited,
    .nb-cat-button-special-offers:active {   
        text-transform: uppercase;         
        background-color: var(--main-blue);       
    }

    .nb-cat-button p {
        font-size: var(--font-size-sm);
        text-align: center;
    }
    @media screen and (max-width: 1200px) {
        .nb-cat-button p {
            font-size: 0.67rem;
        }
    }
    
    .nb-cat-button:hover {
        color: var(--text-white);
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size-sm);
        background-color: var(--main-red-hover);
        cursor: pointer;
    }
    .nb-cat-button-special-offers:hover {
        background-color: var(--main-blue-hover);   
    }

    .nb-dropdown {
        position: absolute;
        display: block;
        width: 100vw;
        height: 300px;
        bottom: -300px;
        left: 0;
        right: 0;
        background-color: var(--bg);
        z-index: 5;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        color: var(--text-black);
        padding: 32px;
        padding-left:64px;
    }
    .nb-dropdown-hidden {
        position: absolute;
        display: none;
        width: 100vw;
        height: 300px;
        bottom: -300px;
        left: 0;
        right: 0;
        background-color: var(--bg);
        z-index: 5;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        color: var(--text-black);
        padding: 32px;
    }

    .nb-dropdown:hover {
        cursor: auto;
    }

    .nb-sc-group div p {
        font-size: var(--font-size-xs);
        font-weight: var(--font-weight-regular);
    }

    .nb-sc-title {
        color: var(--main-red);
    }
    .nb-sc-title:hover {
        cursor: pointer;
    }

    .nb-brand-text {
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-xs);
    }

    .nb-brand-text:hover {
        color: var(--main-red);
    }

    .nb-brand-title:hover {
        cursor: pointer;
    }

    .nb-ifc-wrapper {
        background-color: var(--main-red);
        color: var(--text-white);
        padding: 8px 16px;
        border-radius: 8px;
        width: 100%;
        flex: 1;
    }
}


/* .banner-message */

.banner-message {
    --_message-fs: clamp(0.5rem, 0.25vw + var(1rem), 1.5rem);
    padding:8px 4px;   
    background-color: var(--main-blue);
    color: var(--text-pure-white);    
    font-weight: var(--font-weight-bold);
    font-size: 0.67rem;
    font-size: var(--_message-fs);
    
    /* from https://codepen.io/hexagoncircle/pen/eYMrGwW */
    --gap: 1rem;
    position: relative;
    display: flex;
    overflow: hidden;    
    gap: var(--gap); 
    white-space: norap;  
    -webkit-user-select: none;
    user-select: none;
}

.banner-message:before,
.banner-message:after {
    position: absolute;
    top: 0;    
    width: 10%;
    height: 100%;
    content: "";
    z-index: 2;
} 

.banner-message:before {    
    left: 0;
    background: linear-gradient(to left, rgba(255,255,255,0), var(--main-blue));    
}

.banner-message:after {    
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0), var(--main-blue));    
}

.banner-message p {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}


/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
    .banner-message {
        animation-play-state: paused !important;
    }
}
  
/* Enable animation */
.banner-message p {
    animation: scroll 25s linear infinite;
}

/* Pause on hover */
.banner-message:hover p {
    animation-play-state: paused;
}