.category-row-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    scrollbar-width: none;
    display: flex !important;
    z-index: 1;
}

.desktop-category-row-wrapper {
    display: none !important;
}

.category-card-wrapper {
    width: 100%;
    flex: 0 0 auto;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.cat-card-image {
    min-height: 150px;
    overflow: hidden;
}

.cat-card-image img {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.cat-name {
    padding-top: 4px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
}

.category-card-row-wrapper:hover .cat-arrow {
    transform: translateY(0) rotate(0);
    transition: 0.3s;
}

.category-card-row-wrapper .cat-arrow {
    transform: translateY(40px) rotate(30deg);
    transition: 0.3s;
}

.cat-arrow-wrapper {
    overflow: hidden;
}

.clearance-no-items {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 64px;
    align-items: center;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-lg);
    color: var(--text-black-faded);
}

.category-card-row-wrapper {
    display: inline-block;
    width: 90%;
    height: auto;
    flex: 0 0 auto;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .desktop-category-row-wrapper {
        width: 100%;
        flex-wrap: nowrap;
        scrollbar-width: none;
        display: flex !important;
        position: relative;
        padding: 8px 0 8px 32px;
        z-index: 1;
    }

    .category-row-wrapper {
        display: none !important;
    }

    .cat-item-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .category-card-row-wrapper {
        display: inline-block;
        width: 90%;
        height: 300px;
        flex: 0 0 auto;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
    }

    .category-card-row-wrapper:hover {
        cursor: pointer;
    }
}

.category-section-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 8px auto;
    padding: 0 16px;
    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-items: stretch;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    /*shortand for grid-column-gap & grid-row-gap */
}


@media screen and (min-width: 768px) {
    .category-section-wrapper {
        grid-template-columns: 1fr 1fr;
    }

    .cat-card-image {
        min-height: 200px;
    }
}

@media screen and (min-width: 992px) {
    .category-section-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .cat-card-image {
        min-height: 250px;
    }
}

@media screen and (min-width: 1200px) {
    .category-section-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 24px;
    }

    .cat-card-image {
        min-height: 250px;
    }
}

/* HERO STYLES */

/* hero */
.hero {
    --_bg-image: linear-gradient(45deg, var(--bg-overlay) 0%, var(--bg) 100%);
    /*none;*/

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 300px;
    width: 100%;
    overflow: hidden;
    padding: 16px;

    /* Background styles */
    background-color: var(--bg-overlay);
    background-image: var(--_bg-image);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    border-radius: 46px 0 46px 0;
}

/* hero-content */
.hero-content {
    width: min(768px, 100%);
    padding: 16px;
    margin: 16px;
    box-shadow: var(--box-shadow);
    border-radius: 46px 0 46px 0;
    color: var(--text-white);
    background-color: var(--bg-dark);
    -webkit-backdrop-filter: blur(20px) brightness(60%);
    backdrop-filter: blur(20px) brightness(60%);
}

.hero-content h1 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-2xl)
}

.hero-content p {
    font-weight: var(--font-weight-light);
    line-height: 1.618rem;
    font-size: var(--font-size-sm);
}

@media screen and (min-width: 1200px) {
    .hero {
        height: 500px;
    }

    .hero-content h1 {
        font-size: var(--font-size-4xl)
    }
}


/* END HERO STYLES */