#about-us {
    width: 100%;
    color: var(--text-black);    
}

#about-us section {
    margin-left: 8px;
    margin-right: 8px;
    padding: 8px 16px;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-content: center;
    font-weight: var(--font-weight-regular);
}

#about-us section p {
    text-indent: var(--font-size-2xl);
    font-size: var(--font-size-sm);
    margin-left: 16px;
    margin-right: 16px;
    text-wrap: pretty;
}

@media screen and (min-width: 1200px) {
	#about-us section {
        width: min(768px, 80%);
        margin: auto;
    } 
    
    #about-us section p:first-child {
        text-indent: unset;
    }
}

#about-us section ul {
    margin: 0;
    padding-left: var(--font-size-2xl);
    padding-right: var(--font-size-2xl);
    list-style-type: none;
}

#about-us section ul li {
    padding-bottom: 8px;
    font-size: var(--font-size-sm)
}

#about-us section ul li:last-child {
    padding-bottom: unset;
}


#about-us section a {
    color: var(--main-red);
    font-weight: var(--font-weight-bold);
}

#about-us section a:hover {
    color: var(--main-red-hover);
}

#about-us .section-heading-wrapper {    
    padding: 16px 0;
    gap: 8px;
}